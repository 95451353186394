<template>
    <div class="applications-form_wrap">
        <Breadcrumbs
            :items="items"
            :formPage="true"
        />
        <h2 class="applications-form_header">{{ projectTitle }}</h2>
        <p v-if="$route.name.includes('student')" class="project-sentence">Merci de saisir vos choix de formation par ordre de priorité, de la plus importante à la moins importante. La
          première formation renseignée sera donc considérée comme votre premier choix. Vous pouvez formuler 7 voeux
          maximum</p>
        <div class="form-status_wrap">
            <form action="" @submit.prevent="saveApplication">
                <div class="form-field">
                    <p>{{ $t('institutionCity') }} <span v-if="requiredHandler('ProjectInstitutioncityId')">*</span></p>
                    <v-select
                        :options="institutionCities"
                        label="name"
                        value="id"
                        v-model="form3.projectInstitutioncityId"
                        class="select-field custom-select"
                    ></v-select>
                </div>
                <div v-if="form3.projectInstitutioncityId && form3.projectInstitutioncityId.id === 10000" class="form-field">
                  <p>{{ $t('otherCity') }} *</p>
                  <md-field>
                    <md-input v-model="form3.othercity" :placeholder="$t('otherCity')" :required="requiredHandler('OtherInstitution')"></md-input>
                  </md-field>
                </div>
                <div class="form-field">
                    <p>{{ $t('nameOfInstitution') }} <span v-if="requiredHandler('ProjectInstitutionId')">*</span></p>
                    <select name="" class="select-field" v-model="form3.projectInstitutionId" :required="requiredHandler('ProjectInstitutionId')">
                        <option
                            v-for="(institution,i) in institutionNames"
                            :selected="form3.projectInstitutionId === institution.id"
                            :key="i"
                            :value="institution.id"
                        >
                            {{ institution.name }}
                        </option>
                    </select>
                </div>
              <div v-if="form3.projectInstitutionId === 10000" class="form-field">
                <p>{{ $t('otherCity2') }} *</p>
                <md-field>
                  <md-input v-model="form3.othercityOfinst" :placeholder="$t('otherCity2')" :required="requiredHandler('othercityOfinst')"></md-input>
                </md-field>
              </div>
                <div v-if="scholarshipTypeHandler('coursewishlevelId')" class="form-field">
                    <p>{{ $t('courseWishLevel') }} <span v-if="requiredHandler('coursewishlevelId')">*</span></p>
                    <select name="" class="select-field" v-model="form3.coursewishlevelId" :required="requiredHandler('coursewishlevelId')">
                        <option
                            v-for="(courseLevel,i) in courseWishLevel"
                            :selected="form3.coursewishlevelId === courseLevel.id"
                            :key="i"
                            :value="courseLevel.id"
                        >
                            {{ courseLevel.name }}
                        </option>
                    </select>
                </div>
                <div v-if="scholarshipTypeHandler('coursewishyearId')" class="form-field">
                    <p>{{ $t('courseWishYear') }} <span v-if="requiredHandler('coursewishyearId')">*</span></p>
                    <select name="" class="select-field" v-model="form3.coursewishyearId" :required="requiredHandler('coursewishyearId')">
                        <option
                            v-for="(courseYear,i) in courseWishYear"
                            :selected="form3.coursewishyearId === courseYear.id"
                            :key="i"
                            :value="courseYear.id"
                        >
                            {{ courseYear.name }}
                        </option>
                    </select>
                </div>
                <div v-if="scholarshipTypeHandler('coursewishfieldId')" class="form-field">
                    <p>{{ $t('courseWishField') }} <span v-if="requiredHandler('coursewishfieldId')">*</span></p>
                    <select name="" class="select-field" v-model="form3.coursewishfieldId" :required="requiredHandler('coursewishfieldId')">
                        <option
                            v-for="(courseField,i) in courseWishField"
                            :selected="form3.coursewishfieldId === courseField.id"
                            :key="i"
                            :value="courseField.id"
                        >
                            {{ courseField.name }}
                        </option>
                    </select>
                </div>
                <div v-if="scholarshipTypeHandler('coursewishname')" class="form-field">
                    <p>{{ $t('courseWishName') }} <span v-if="requiredHandler('coursewishname')">*</span></p>
                    <md-field>
                        <md-input v-model="form3.coursewishname" :placeholder="$t('courseWishName')" :required="requiredHandler('coursewishname')"></md-input>
                    </md-field>
                </div>
                <div v-if="scholarshipTypeHandler('coursewishworkinglanguageId')" class="form-field">
                    <p>{{ $t('courseWishWorkingLanguage') }}<span v-if="requiredHandler('coursewishworkinglanguageId')">*</span></p>
                    <select name="" class="select-field" v-model="form3.coursewishworkinglanguageId" :required="requiredHandler('coursewishworkinglanguageId')">
                        <option
                            v-for="(workLang,i) in courseWishWorkingLanguage"
                            :selected="form3.coursewishworkinglanguageId === workLang.id"
                            :key="i"
                            :value="workLang.id"
                        >
                            {{ workLang.name }}
                        </option>
                    </select>
                </div>
              <div class="form-field">
                <p class="recomendationletter">{{$t('LettreDeRecommandation')}} <span v-if="requiredHandler('recomendationletter')">*</span></p>
                <md-field :class="{'uploaded': form3.recomendationletter}">
                  <md-file
                      @change="changeHandler('recomendationletter', 'recomendationletterStatusid')"
                      :placeholder="$t('LettreDeRecommandation')"
                      :required="requiredFile('recomendationletter', 'recomendationletter')"
                      :value="files.recomendationletter"
                      accept="application/pdf"
                  ></md-file>
                </md-field>
                <div class="document-status">
                  <div v-html="svgApproved('recomendationletterStatusId')"></div>
                  <div v-html="svgDeclined('recomendationletterStatusId')"></div>
                </div>
              </div>
                <div v-if="scholarshipTypeHandler('coursewishmotivationletter')" class="form-field">
                    <p>{{ $t('courseWishMotivationLetterComment') }}</p>
                    <md-field>
                        <md-input v-model="form3.coursewishmotivationletterComment" :placeholder="$t('courseWishMotivationLetterComment')" />
                    </md-field>
                </div>
              <div v-if="scholarshipTypeHandler('projectdescription')" class="form-field">
                <p>{{ $t('projectDescriptionComment') }}</p>
                <md-field>
                  <md-input v-model="form3.projectdescriptionComment" :placeholder="$t('')"/>
                </md-field>
              </div>
                <div v-if="scholarshipTypeHandler('coursewishadmissionletter')">
                    <div class="form-field">
                        <p>{{ $t('courseWishAdmissionLetter') }} <span v-if="requiredHandler('coursewishadmissionletter')">*</span></p>
                        <md-field :class="{'uploaded': form3.coursewishadmissionletter}">
                            <md-file
                                @change="changeHandler('coursewishadmissionletter', 'coursewishadmissionletterStatusId')"
                                :placeholder="$t('courseWishAdmissionLetter')"
                                :required="requiredFile('coursewishadmissionletter', 'coursewishadmissionletter')"
                                :value="files.coursewishadmissionletter"
                                accept="application/pdf"
                            ></md-file>
                        </md-field>
                        <div class="document-status">
                            <div v-html="svgApproved('coursewishadmissionletterStatusId')"></div>
                            <div v-html="svgDeclined('coursewishadmissionletterStatusId')"></div>
                        </div>
                    </div>
                    <div v-if="filesError.coursewishadmissionletter" class="form-field">
                        <p></p>
                        <span class="file-error-msg">{{fileErrorMsg}}</span>
                    </div>
                </div>
                <div v-if="scholarshipTypeHandler('coursewishadmissionletter')" class="form-field">
                    <p>{{ $t('courseWishAdmissionLetterComment') }}</p>
                    <md-field>
                        <md-input v-model="form3.coursewishadmissionletterComment" :placeholder="$t('courseWishAdmissionLetterComment')" />
                    </md-field>
                </div>
                <div v-if="scholarshipTypeHandler('coursewishprogramId')" class="form-field">
                    <p>{{ $t('courseWishProgram') }} <span v-if="requiredHandler('coursewishprogramId')">*</span></p>
                    <select name="" class="select-field" v-model="form3.coursewishprogramId" :required="requiredHandler('coursewishprogramId')">
                        <option
                            v-for="(program,i) in courseWishProgram"
                            :selected="form3.coursewishprogramId === program.id"
                            :key="i"
                            :value="program.id"
                        >
                            {{ program.name }}
                        </option>
                    </select>
                </div>
                <div v-if="scholarshipTypeHandler('laboratory')" class="form-field">
                    <p>{{ $t('laboratory') }} <span v-if="requiredHandler('laboratory')">*</span></p>
                    <md-field>
                        <md-input v-model="form3.laboratory" :placeholder="$t('laboratory')" :required="requiredHandler('laboratory')"></md-input>
                    </md-field>
                </div>
                <div v-if="scholarshipTypeHandler('unitnumber')" class="form-field">
                    <p>{{ $t('unitNumber') }} <span v-if="requiredHandler('unitnumber')">*</span></p>
                    <md-field :md-counter="false">
                        <md-input v-model="form3.unitnumber" :placeholder="$t('unitNumber')" maxlength="15" :required="requiredHandler('unitnumber')"></md-input>
                    </md-field>
                </div>
                <div v-if="scholarshipTypeHandler('institutioncontactname')" class="form-field">
                    <p>{{ $t('institutionContactName') }} <span v-if="requiredHandler('institutioncontactname')">*</span></p>
                    <md-field>
                        <md-input
                            v-model="form3.institutioncontactname"
                            :placeholder="$t('institutionContactName')"
                            :required="requiredHandler('institutioncontactname')"
                        ></md-input>
                    </md-field>
                </div>
                <div v-if="scholarshipTypeHandler('institutioncontactemail')" class="form-field">
                    <p>{{ $t('institutionContactEmail') }} <span v-if="requiredHandler('institutioncontactemail')">*</span></p>
                    <md-field>
                        <md-input
                            type="text"
                            v-model="form3.institutioncontactemail"
                            :placeholder="$t('institutionContactEmail')"
                            :required="requiredHandler('institutioncontactemail')"
                        ></md-input>
                    </md-field>
                </div>
                <div v-if="scholarshipTypeHandler('projectname')" class="form-field">
                    <p>{{ $t('projectName') }} <span v-if="requiredHandler('projectname')">*</span></p>
                    <md-field>
                        <md-input
                            v-model="form3.projectname"
                            :placeholder="$t('projectName')"
                            :required="requiredHandler('projectname')"
                        ></md-input>
                    </md-field>
                </div>
                <div v-if="scholarshipTypeHandler('scientificspecialityId')" class="form-field">
                    <p>{{ $t('scientificSpeciality') }} <span v-if="requiredHandler('scientificspecialityId')">*</span></p>
                    <select name="" class="select-field" v-model="form3.scientificspecialityId" :required="requiredHandler('scientificspecialityId')">
                        <optgroup
                            v-for="(title,i) in scientificSpeciality"
                            :label="title.name"
                            :key="i"
                        >
                            <option
                                v-for="speciality in title.children"
                                :selected="form3.scientificspecialityId === speciality.id"
                                :key="speciality.id"
                                :value="speciality.id"
                            >
                                {{ speciality.name }}
                            </option>
                        </optgroup>
                    </select>
                </div>
              <div v-if="form3.scientificspecialityId === 10000" class="form-field">
                <p>{{ $t('OtherSpeciality') }}</p>
                <md-field>
                  <md-input
                      v-model="form3.otherSpeciality"
                      :placeholder="$t('OtherSpeciality')"
                  ></md-input>
                </md-field>
              </div>
                <div v-if="scholarshipTypeHandler('durationofstayinFranceId')" class="form-field">
                    <p>{{ $t('durationOfStayInFrance') }} <span v-if="requiredHandler('durationofstayinFranceId')">*</span> </p>
                    <select name="" class="select-field" v-model="form3.durationofstayinFranceId" :required="requiredHandler('durationofstayinFranceId')">
                        <option
                            v-for="(duration,i) in durationofstayinFrance"
                            :selected="form3.durationofstayinFranceId === duration.id"
                            :key="i"
                            :value="duration.id"
                        >
                            {{ duration.name }}
                        </option>
                    </select>
                </div>
                <div v-if="scholarshipTypeHandler('projectdescription')">
                    <div class="form-field">
                        <p>{{ $t('projectDescription') }} <span v-if="requiredHandler('projectdescription')">*</span></p>
                        <md-field :class="{'uploaded': form3.projectdescription}">
                            <md-file
                                @change="changeHandler('projectdescription', 'projectdescriptionStatusId')"
                                :placeholder="$t('projectDescription')"
                                :required="requiredFile('projectdescription', 'projectdescription')"
                                :value="files.projectdescription"
                                accept="application/pdf"
                            ></md-file>
                        </md-field>
                        <div class="document-status">
                            <div v-html="svgApproved('projectdescriptionStatusId')"></div>
                            <div v-html="svgDeclined('projectdescriptionStatusId')"></div>
                        </div>
                    </div>
                    <div v-if="filesError.projectdescription" class="form-field">
                        <p></p>
                        <span class="file-error-msg">{{fileErrorMsg}}</span>
                    </div>
                </div>
                <div v-if="scholarshipTypeHandler('Invitationoradmissionletter')">
                    <div class="form-field">
                        <p>{{ $t('invitationOrAdmissionLetter') }} <span v-if="requiredHandler('Invitationoradmissionletter')">*</span></p>
                        <md-field :class="{'uploaded': form3.invitationoradmissionletter}">
                            <md-file
                                @change="changeHandler('invitationoradmissionletter', 'invitationoradmissionletterStatusId')"
                                :placeholder="$t('invitationOrAdmissionLetter')"
                                :required="requiredFile('Invitationoradmissionletter', 'invitationoradmissionletter')"
                                :value="files.invitationoradmissionletter"
                                accept="application/pdf"
                            ></md-file>
                        </md-field>
                        <div class="document-status">
                            <div v-html="svgApproved('invitationoradmissionletterStatusId')"></div>
                            <div v-html="svgDeclined('invitationoradmissionletterStatusId')"></div>
                        </div>
                    </div>
                    <div v-if="filesError.invitationoradmissionletter" class="form-field">
                        <p></p>
                        <span class="file-error-msg">{{fileErrorMsg}}</span>
                    </div>
                </div>
                <div v-if="scholarshipTypeHandler('Invitationoradmissionletter')" class="form-field">
                    <p>{{ $t('invitationOrAdmissionLetterComment') }}</p>
                    <md-field>
                        <md-input v-model="form3.invitationoradmissionletterComment" :placeholder="$t('invitationOrAdmissionLetterComment')"/>
                    </md-field>
                </div>
                <div v-if="scholarshipTypeHandler('endorsementletter')">
                    <div class="form-field">
                        <p>{{ $t('endorsementLetter') }} <span v-if="requiredHandler('endorsementletter')">*</span></p>
                        <md-field :class="{'uploaded': form3.endorsementletter}">
                            <md-file
                                @change="changeHandler('endorsementletter', 'endorsementletterStatusId')"
                                :placeholder="$t('endorsementLetter')"
                                :required="requiredFile('endorsementletter', 'endorsementletter')"
                                :value="files.endorsementletter"
                                accept="application/pdf"
                            ></md-file>
                        </md-field>
                        <div class="document-status">
                            <div v-html="svgApproved('endorsementletterStatusId')"></div>
                            <div v-html="svgDeclined('endorsementletterStatusId')"></div>
                        </div>
                        <div v-if="showEndorsementLetterTooltip" class="tooltip-wrap">
                            <img src="@/assets/images/info.svg" alt="info" @click="endorsementLetterTooltipActive = !endorsementLetterTooltipActive" />
                            <md-tooltip :md-active.sync="endorsementLetterTooltipActive" md-direction="right">
                                <p v-html="endorsementLetterTooltipMsg"></p>
                            </md-tooltip>
                        </div>
                    </div>
                    <div v-if="filesError.endorsementletter" class="form-field">
                        <p></p>
                        <span class="file-error-msg">{{fileErrorMsg}}</span>
                    </div>
                </div>
                <div v-if="scholarshipTypeHandler('endorsementletter')" class="form-field">
                    <p>{{ $t('endorsementLetterComment') }}</p>
                    <md-field>
                        <md-input v-model="form3.endorsementletterComment" :placeholder="$t('endorsementLetterComment')"/>
                    </md-field>
                </div>
                <div v-if="scholarshipTypeHandler('thesisdirectorname')" class="form-field">
                    <p>{{ $t('projectDoctorateThesisDirectorName') }} <span v-if="requiredHandler('thesisdirectorname')">*</span></p>
                    <md-field>
                        <md-input
                            v-model="form3.thesisdirectorname"
                            :placeholder="$t('projectDoctorateThesisDirectorName')"
                            :required="requiredHandler('thesisdirectorname')"
                        ></md-input>
                    </md-field>
                </div>
                <div v-if="scholarshipTypeHandler('thesisdirectoremail')" class="form-field">
                    <p>{{ $t('projectDoctorateThesisDirectorEmail') }} <span v-if="requiredHandler('thesisdirectoremail')">*</span></p>
                    <md-field>
                        <md-input
                            type="email"
                            v-model="form3.thesisdirectoremail"
                            :placeholder="$t('projectDoctorateThesisDirectorEmail')"
                            :required="requiredHandler('thesisdirectoremail')"></md-input>
                    </md-field>
                </div>
                <div v-if="scholarshipTypeHandler('conventionprocessletter')">
                    <div class="form-field">
                        <p>{{ $t('conventionProcessLetter') }} <span v-if="requiredHandler('conventionprocessletter')">*</span></p>
                        <md-field :class="{'uploaded': form3.conventionprocessletter}">
                            <md-file
                                @change="changeHandler('conventionprocessletter', 'conventionprocessletterStatusId')"
                                placeholder="Convention ou courrier"
                                :required="requiredFile('conventionprocessletter', 'conventionprocessletter')"
                                :value="files.conventionprocessletter"
                                accept="application/pdf"
                                multiple
                            ></md-file>
                        </md-field>
                        <div class="document-status">
                            <div v-html="svgApproved('conventionprocessletterStatusId')"></div>
                            <div v-html="svgDeclined('conventionprocessletterStatusId')"></div>
                        </div>
                    </div>
                    <div v-if="filesError.conventionprocessletter" class="form-field">
                        <p></p>
                        <span class="file-error-msg">{{fileErrorMsg}}</span>
                    </div>
                </div>
                <div v-if="scholarshipTypeHandler('conventionprocessletter')" class="form-field">
                    <p>{{ $t('conventionProcessLetterComment') }}</p>
                    <md-field>
                        <md-input v-model="form3.conventionprocessletterComment" :placeholder="$t('conventionProcessLetterComment')"/>
                    </md-field>
                </div>
                <div class="form-field">
                    <p>{{ $t('projectComment') }}</p>
                    <md-field>
                        <md-input v-model="form3.comment" :placeholder="$t('projectComment')"></md-input>
                    </md-field>
                </div>
                <div class="form-field applications-error">
                    <p></p>
                    <Errors
                        :errors="errors"
                        class="applications"
                    />
                </div>
                <div class="form-field">
                    <p></p>
                    <md-button class="md-raised cancel" @click="showModal">
                        <span>Annuler</span>
                    </md-button>
                  <md-button :class="{'disabled': disabled}" :disabled="disabled" v-if="$route.name.includes('student') && isMaster" @click="saveAndCreate" class="next-save">
                    <span>Sauver et créer un nouveau projet</span>
                  </md-button>
                    <md-button :class="{'disabled': disabled}" :disabled="disabled" type="submit" class="next">
                        <span>Sauvegarder</span>
                    </md-button>
                </div>
            </form>
        </div>
      <CloseModal
          v-if="showCloseModal"
          :application="true"
          @cancel="cancel"
      />
    </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import {errors} from "@/mixins/errors"
import Errors from "@/components/Errors";
import Breadcrumbs from '@/components/breadcrumbs';
import CloseModal from '@/components/cancel-modal';

export default {
    name: 'Project',
    mixins: [
        errors,
    ],
    components: {
        Errors,
        Breadcrumbs,
        CloseModal,
    },
    data: () => ({
        showCloseModal: false,
        disabled: false,
        existingProjectsCount: 0,
        edit: false,
        files: {},
        filesError: {},
        fileErrorMsg: 'Merci de convertir votre fichier au format pdf avant de le télécharger',
        filteredInstitutionCities: [],
        scholarshipId: '',
        form3: {
            id: 0,
            applicationId: 0,
            // projectStatusId: 1,
            projectInstitutioncityId: '',
            projectInstitutionId: '',
            othercity: '',
            othercityOfinst: '',
            nameofinstitutionId: '',
            coursewishlevelId: '',
            coursewishyearId: '',
            coursewishfieldId: '',
            coursewishname: '',
            coursewishworkinglanguageId: '',
            coursewishmotivationletter: '',
            coursewishmotivationletterStatusId: 1,
            coursewishmotivationletterComment: '',
            recomendationletter: '',
            coursewishadmissionletter: '',
            coursewishadmissionletterStatusId: 1,
            coursewishadmissionletterComment: '',
            coursewishprogramId: '',
            institutioncityId: '',
            institutioncontactname: '',
            institutioncontactemail: '',
            laboratory: '',
            unitnumber: '',
            projectname: '',
            otherSpeciality: '',
            scientificspecialityId: '',
            durationofstayinFranceId: '',
            projectdescription: '',
            projectdescriptionStatusId: 1,
            projectdescriptionComment: '',
            invitationoradmissionletter: '',
            invitationoradmissionletterStatusId: 1,
            invitationoradmissionletterComment: '',
            endorsementletter: '',
            endorsementletterStatusId: 1,
            endorsementletterComment: '',
            thesisdirectorname: '',
            thesisdirectoremail: '',
            conventionprocessletter: '',
            conventionprocessletterStatusId: 1,
            conventionprocessletterComment: '',
            comment: '',
        },
        items: [
            {
                text: 'home',
                href: '/'
            },
            {
                text: 'project',
                href: '#',
            },
        ],
        endorsementLetterTooltipMsgOstrogradski: 'De votre directeur de thèse russe',
        endorsementLetterTooltipMsgVernadski: 'Lettres  du directeur de thèse russe <br /> et du directeur de thèse français',
        endorsementLetterTooltipActive: false,
    }),
    computed: {
        ...mapGetters('applications',['scholarships', 'projectMetaInfo', 'project', 'currentScholarshipTypeId', 'formattedScholarships']),
        ...mapGetters(['simpleLists']),
        projectTitle () {
          let title
          switch (this.scholarshipType) {
            case 'Ostrogradski':
              title = `${ this.$t( 'project' ) } BOURSE OSTROGRADSKI`
              break;
            case 'Metchnikov':
              title = `${ this.$t( 'project' ) } BOURSE METCHNIKOV`
              break;
            case 'Vernadski':
              title = `${ this.$t( 'project' ) } BOURSE VERNADSKI`
              break;
            case 'Master':
              title = `${ this.$t( 'project' ) } BOURSE MASTER`
              break;
            default:
              title = this.$t( 'project' )
          }
          return title
        },
        isMaster() {
            return parseInt(this.$route.params.id) === 4
        },
        disabledSelectedValidated() {
            let disabled = false
            if(this.isMaster && (!this.form3.coursewishadmissionletter || !this.form3.coursewishmotivationletter)) {
                disabled = true
            }
            return disabled
        },
        currentScholarships() {
            let filteredScholarships = [];
            this.scholarships?.forEach(item => {
                if(item.scholarshipTypeId === parseInt(this.$route.params.id) && !item.deactivated) {
                    filteredScholarships.push(item)
                }
            });
            return filteredScholarships.length > 0 ? filteredScholarships : this.scholarships
        },
        scholarshipTypes() {
            return this.simpleLists?.scholarshipType
        },
        scholarshipType() {
            const id = this.currentScholarshipTypeId|| this.$route.params.id;
            const currentType = this.scholarshipTypes?.filter(type => type.id == id);
            return currentType ? currentType[0]?.name : ''
        },
        institutionCities() {
            return this.simpleLists?.projectInstitutioncity
        },
        institutionNames() {
            let data = [];
            if(this.form3.projectInstitutioncityId?.id) {
                this.simpleLists?.projectInstitution.forEach(item => {
                    if (item.name === 'Autre établissement') {
                        data.push(item)
                    }
                    if(item.projectInstitutioncityId === this.form3.projectInstitutioncityId.id) {
                        data.push(item)
                    }
                })
            } else {
                data = this.simpleLists?.projectInstitution
            }
            return data
        },
        projectStatuses() {
            return this.simpleLists?.projectStatus
        },
        courseWishLevel() {
            return this.simpleLists?.coursewishlevel
        },
        courseWishYear() {
            return this.simpleLists?.coursewishyear
        },
        courseWishField() {
            return this.simpleLists?.coursewishfield
        },
        courseWishWorkingLanguage() {
            return this.simpleLists?.coursewishworkinglanguage
        },
        courseWishProgram() {
            return this.simpleLists?.coursewishprogram
        },
        scientificSpeciality() {
            const titles = this.simpleLists?.scientificspecialitytitle
            titles.map(item => {
                item.children = []
            })
            const list = this.simpleLists?.scientificspeciality
            list.forEach(item => {
                titles.map(title => {
                    if (item.scientificspecialitytitleId === title.id || item.id === 10000) {
                        title.children.push(item)
                    }
                })
            })
            return titles
        },
        durationofstayinFrance() {
            return this.simpleLists?.durationofstayinFrance
        },
        showEndorsementLetterTooltip() {
            return this.scholarshipType ===  "Ostrogradski" ||
                this.scholarshipType ===  "Vernadski"
        },
        endorsementLetterTooltipMsg() {
            return this.scholarshipType ===  "Vernadski" ? this.endorsementLetterTooltipMsgVernadski : this.endorsementLetterTooltipMsgOstrogradski
        },
        isMetchnikovOrOstrogradski() {
          return this.scholarshipType ===  "Ostrogradski" ||
              this.scholarshipType ===  "Metchnikov"
        }
    },
    watch: {
        $route() {
            this.clearErrors()
        },
        'form3.nameofinstitutionId'(id) {
            this.filteredInstitutionCities = this.institutionCities?.filter(el => el.nameofinstitutionId === id)
        },
        'form3.coursewishname'(newV, oldV) {
            this.watchHandler(newV, oldV, 'coursewishname')
        },
        'form3.laboratory'(newV, oldV) {
            this.watchHandler(newV, oldV, 'laboratory')
        },
        'form3.unitnumber'(newV, oldV) {
            this.watchHandler(newV, oldV, 'unitnumber')
        },
        'form3.institutioncontactname'(newV, oldV) {
            this.watchHandler(newV, oldV, 'institutioncontactname')
        },
        'form3.institutioncontactemail'(newV, oldV) {
            this.watchHandler(newV, oldV, 'institutioncontactemail')
        },
        'form3.projectname'(newV, oldV) {
            this.watchHandler(newV, oldV, 'projectname')
        },
        'form3.thesisdirectorname'(newV, oldV) {
            this.watchHandler(newV, oldV, 'thesisdirectorname')
        },
        'form3.thesisdirectoremail'(newV, oldV) {
            this.watchHandler(newV, oldV, 'thesisdirectoremail')
        },
        institutionCities(val) {
            if (val) {
                this.form3.institutioncityId = this.institutionCities?.find(city => city.id === this.form3.institutioncityId)
                this.form3.projectInstitutioncityId = this.institutionCities?.find(city => city.id === this.form3.projectInstitutioncityId)
            }
        },
        'form3.projectInstitutioncityId'(val) {
          if (val) {
            if (val.id === 33) {
              this.institutionNames.push(val)
            }
          }
        }
    },
    async mounted() {
        await this.getAccountProjectFieldsMetaInfo()
        this.form3.applicationId = parseInt(this.$route.params.applicationId);
        await this.getAllScholarships();
        this.filteredInstitutionCities = this.simpleLists?.institutioncity;
        if(!this.$route.name.includes('student')) {
            await this.getProjectData()
        } else {
            this.getLastScholarshipId()
        }
        this.form3.institutioncityId = this.institutionCities?.find(city => city.id === this.form3.institutioncityId)
        this.form3.projectInstitutioncityId = this.institutionCities?.find(city => city.id === this.form3.projectInstitutioncityId)
        this.formattedFiles()
    },
    methods: {
        ...mapActions('auth', ['logout']),
        ...mapMutations('applications', ['setProjectData']),
        ...mapActions('applications',['getAllScholarships', 'getAccountProjectFieldsMetaInfo', 'saveProject', 'getProject']),
        formattedFiles() {
            this.files.coursewishmotivationletter = this.form3.coursewishmotivationletter?.replace('Files/', ' ');
            this.files.recomendationletter = this.form3.recomendationletter?.replace('Files/', ' ');
            this.files.coursewishadmissionletter = this.form3.coursewishadmissionletter?.replace('Files/', ' ');
            this.files.projectdescription = this.form3.projectdescription?.replace('Files/', ' ');
            this.files.invitationoradmissionletter = this.form3.invitationoradmissionletter?.replace('Files/', ' ');
            this.files.endorsementletter = this.form3.endorsementletter?.replace('Files/', ' ');
            this.files.conventionprocessletter = this.form3.conventionprocessletter?.replace('Files/', ' ');
        },
        async cancel() {
            if(this.currentScholarshipTypeId) {
                this.$router.push('/application')
            } else {
                const success = await this.logout()
                if (success) {
                    this.$router.push('/')
                }
            }
        },
        showModal() {
          this.showCloseModal = true
        },
        getLastScholarshipId() {
            if(this.$route.params.id) {
                const currentDay = this.$moment(new Date()).format('YYYY-MM-DD')
                const currentTypeScholarships = this.formattedScholarships[this.$route.params.id]
                currentTypeScholarships.forEach(el => {
                    const closingDate = this.$moment(el.closingDate).format('YYYY-MM-DD')
                    if(this.$moment(currentDay).isBefore(closingDate)) {
                        this.scholarshipId = el.id
                    }
                })
            }
        },
        async getProjectData() {
//            await this.$axios
//                .get(process.env.VUE_APP_API_URL + `/AccountProject/${this.$route.params.id}`)
//                .then(({data}) => {
//                    if (data.success) {
//                        this.setProjectData(data)
//                        for(let field in this.form3) {
//                            if(data[field] !== "null") {
//                                this.form3[field] = data[field]
//                            }
//                        }
//                    }
//                })
//                .catch(error => console.log(error));
        },
        selectDocumentStatus(field, value) {
            this.form3[field] = value
        },
        svgApproved(field) {
            return (this.form3[field] === 2) ? `<svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M19.879 0.578766C20.5132 1.07185 20.6277 1.98568 20.1346 2.61988L8.82556 17.1653C8.5737 17.4892 8.19676 17.6918 7.78761 17.7229C7.37847 17.7541 6.97521 17.6109 6.67725 17.3288L0.531697 11.5106C-0.0516634 10.9583 -0.0768557 10.0377 0.475429 9.45435C1.02771 8.87099 1.94833 8.8458 2.53169 9.39808L7.51302 14.114L17.8379 0.834273C18.331 0.20008 19.2449 0.0856857 19.879 0.578766Z" fill="#0045FF"/>
                </svg>`
                :
                `<svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M19.879 0.578766C20.5132 1.07185 20.6277 1.98568 20.1346 2.61988L8.82556 17.1653C8.5737 17.4892 8.19676 17.6918 7.78761 17.7229C7.37847 17.7541 6.97521 17.6109 6.67725 17.3288L0.531697 11.5106C-0.0516634 10.9583 -0.0768557 10.0377 0.475429 9.45435C1.02771 8.87099 1.94833 8.8458 2.53169 9.39808L7.51302 14.114L17.8379 0.834273C18.331 0.20008 19.2449 0.0856857 19.879 0.578766Z" fill="#CDD0D9"/>
                </svg>`
        },
        svgDeclined(field) {
            return (this.form3[field] === 3) ? `<svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.9951 17.5362C16.4921 18.0332 17.298 18.0332 17.795 17.5362C18.292 17.0392 18.292 16.2333 17.795 15.7363L11.0586 8.99992L17.795 2.26352C18.292 1.76648 18.292 0.960639 17.795 0.463608C17.298 -0.0334225 16.4921 -0.0334225 15.9951 0.463608L9.25871 7.20002L2.52229 0.463594C2.02526 -0.0334377 1.21941 -0.0334377 0.722384 0.463594C0.225352 0.960624 0.225352 1.76647 0.722384 2.26351L7.45881 8.99992L0.722382 15.7363C0.225352 16.2333 0.225352 17.0392 0.722382 17.5362C1.21941 18.0333 2.02526 18.0333 2.52229 17.5362L9.25871 10.7998L15.9951 17.5362Z" fill="#FF4646"/>
                </svg>`
                :
                `<svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.9951 17.5362C16.4921 18.0332 17.298 18.0332 17.795 17.5362C18.292 17.0392 18.292 16.2333 17.795 15.7363L11.0586 8.99992L17.795 2.26352C18.292 1.76648 18.292 0.960639 17.795 0.463608C17.298 -0.0334225 16.4921 -0.0334225 15.9951 0.463608L9.25871 7.20002L2.52229 0.463594C2.02526 -0.0334377 1.21941 -0.0334377 0.722384 0.463594C0.225352 0.960624 0.225352 1.76647 0.722384 2.26351L7.45881 8.99992L0.722382 15.7363C0.225352 16.2333 0.225352 17.0392 0.722382 17.5362C1.21941 18.0333 2.02526 18.0333 2.52229 17.5362L9.25871 10.7998L15.9951 17.5362Z" fill="#CDD0D9"/>
                </svg>`
        },
        watchHandler(newV, oldV, field) {
            const regex = /^([\s!@#$%^&*()_+=-`~\\\\[{}|';:/.,?><0-9A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/;
            if(newV && newV.match(regex) === null) {
                this.$nextTick(() => {
                    this.form3[field] = oldV;
                })
            }
        },
        scholarshipTypeHandler(field) {
            return this.projectMetaInfo?.[field]?.[this.scholarshipType]
        },
        scholarshipName(scholarship) {
            let name = '';
            this.scholarshipTypes?.forEach(el => {
                if(el.id === scholarship.scholarshipTypeId) {
                    name = el.name
                }
            });
            return name + ' ' + this.$moment(scholarship.openingDate).format('DD/MM/YYYY')
        },
        requiredHandler(field) {
            return this.projectMetaInfo?.[field]?.Mandatory
        },
        requiredFile(field, key) {
            if(this.form3[key]) {
                return;
            }
            return this.projectMetaInfo?.[field]?.Mandatory
        },
        changeHandler(field, status) {
            if (event.target.files[0].type !== 'application/pdf') {
                this.filesError[field] = true;
                this.form3[status] = '';
                this.form3[field] = '';
                this.files[field] = '';
                return;
            }
            this.form3[status] = 1;
            this.form3[field] = event.target.files[0];
            this.files[field] = event.target.files[0].name;
            this.filesError[field] = false;
        },
        disabledHandler(id) {
            return this.disabledSelectedValidated && (id !== 1) ||
                this.$route.query.hasSelected == true && id === 3
        },
       async saveAndCreate() {
//         this.clearErrors();
//         this.disabled = true
//         const dataForApplication = {
//           id:  this.$route.params.applicationId || 0,
//           studentId: this.$route.params.studentId,
//           scholarshipId: this.scholarshipId,
//         };
//
//         this.form3.institutioncityId =  this.form3.institutioncityId?.id
//         this.form3.projectInstitutioncityId =  this.form3.projectInstitutioncityId?.id
//
//         const applicationId = JSON.parse(localStorage.getItem('applicationId'))
//         if (!applicationId) {
//           await this.$axios
//               .post(process.env.VUE_APP_API_URL + '/AccountApplication/edit/', dataForApplication)
//               .then(({data}) => {
//                 this.disabled = false
//                 if (data.success) {
//                   this.form3.applicationId = data.id;
//                   localStorage.setItem('currentScholarshipTypeId', this.scholarshipId);
//                   localStorage.setItem('applicationId', data.id);
//                 } else {
//                   this.setErrors([data.error])
//                 }
//               }).catch((err) => {
//                 this.setErrors(err.response.data.errors)
//               })
//         }
//         const currentFormData = new FormData();
//         let projectData = {};
//         for (let key in this.form3) {
//           if(this.form3[key]) {
//             projectData[key] = this.form3[key]
//           }
//         }
//         if (applicationId) {
//           Object.assign(projectData, {applicationId})
//         }
//         for (let field in projectData) {
//           currentFormData.append(field, projectData[field]);
//         }
//         if (this.existingProjectsCount <= 6) {
//           this.$axios
//               .post(process.env.VUE_APP_API_URL + '/AccountProject/Edit/', currentFormData, {
//                 headers: {
//                   'Content-Type': 'multipart/form-data',
//                 }
//               }).then((response) => {
//                 this.disabled = false
//                 this.existingProjectsCount = response.data.existingProjectsCount
//                 for (let key in this.form3) {
//                   this.form3[key] = ''
//                 }
//                 this.form3.coursewishmotivationletterStatusId = 1;
//                 this.form3.coursewishadmissionletterStatusId = 1;
//                 this.form3.projectdescriptionStatusId =  1;
//                 this.form3.invitationoradmissionletterStatusId = 1;
//                 this.form3.endorsementletterStatusId = 1
//                 this.form3.conventionprocessletterStatusId = 1;
//                 for (let key in this.files) {
//                   this.files[key] = ''
//                 }
//                 document.querySelectorAll('input[type="file"]').forEach(item => {
//                   item.value = ''
//                 })
//                 if(response.data.success) {
//                   this.$notify({
//                     group: 'success-notify',
//                     type: 'success',
//                     title: this.$t('savedMessage'),
//                     text: '',
//                   });
//                 } else {
//                   this.setErrors([response.data.error])
//                 }
//           })
//         } else {
//           localStorage.removeItem('applicationId');
//           this.$router.push('/application')
//         }
       },
       async saveApplication() {
//           this.disabled = true
//           this.clearErrors();
//           const dataForApplication = {
//               id:  this.$route.params.applicationId || 0,
//               studentId: this.$route.params.studentId,
//               scholarshipId: this.scholarshipId,
//           };
//           let localData = localStorage.getItem('bgfNewData')
//           localStorage.setItem('bgfCandidate', localData)
//           this.form3.institutioncityId =  this.form3.institutioncityId?.id
//           this.form3.projectInstitutioncityId =  this.form3.projectInstitutioncityId?.id
//           if(!this.$route.name.includes('student')) {
//               let projectData = {};
//               for (let key in this.form3) {
//                   if(this.form3[key]) {
//                       projectData[key] = this.form3[key]
//                   }
//               }
//               const currentFormData = new FormData();
//               for (let field in projectData) {
//                   currentFormData.append(field, projectData[field]);
//               }
//               this.$axios
//                   .post(process.env.VUE_APP_API_URL + '/AccountProject/Edit/', currentFormData, {
//                       headers: {
//                           'Content-Type': 'multipart/form-data',
//                       }
//                   }).then((response) => {
//                       this.disabled = false
//                       if(response.data.success) {
//                           this.$notify({
//                               group: 'success-notify',
//                               type: 'success',
//                               title: this.$t('savedMessage'),
//                               text: '',
//                           });
//                           this.$router.push('/application')
//                       } else {
//                           this.setErrors([response.data.error])
//                       }
//               })
//           } else {
//             this.clearErrors();
//             const applicationId = JSON.parse(localStorage.getItem('applicationId'))
//             if (!applicationId) {
//               await this.$axios
//                   .post(process.env.VUE_APP_API_URL + '/AccountApplication/edit/', dataForApplication)
//                   .then(({data}) => {
//                     this.disabled = false
//                     if (data.success) {
//                       this.form3.applicationId = data.id;
//                       localStorage.setItem('currentScholarshipTypeId', this.scholarshipId);
//                       localStorage.setItem('applicationId', data.id);
//                     } else {
//                       this.setErrors([data.error])
//                     }
//                   }).catch((err) => {
//                     this.setErrors(err.response.data.errors)
//                   })
//             }
//             const currentFormData = new FormData();
//             let projectData = {};
//             for (let key in this.form3) {
//               if(this.form3[key]) {
//                 projectData[key] = this.form3[key]
//               }
//             }
//             if (applicationId) {
//               Object.assign(projectData, {applicationId})
//             }
//             for (let field in projectData) {
//               currentFormData.append(field, projectData[field]);
//             }
//             this.$axios
//                 .post(process.env.VUE_APP_API_URL + '/AccountProject/Edit/', currentFormData, {
//                   headers: {
//                     'Content-Type': 'multipart/form-data',
//                   }
//                 }).then((response) => {
//               this.existingProjectsCount = response.data.existingProjectsCount
//               this.disabled = false
//               if(response.data.success) {
//                 localStorage.removeItem('applicationId');
//                 this.$router.push('/application')
//
//                 this.$notify({
//                   group: 'success-notify',
//                   type: 'success',
//                   title: this.$t('savedMessage'),
//                   text: '',
//                 });
//               } else {
//                 this.setErrors([response.data.error])
//               }
//             })
//           }
        }
    }
}
</script>

<style lang="scss">
.applications-form_wrap {
    width: 920px;
    .form-status_wrap {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        form {
            width: 100%;
        }
    }
    .md-datepicker {
        align-items: center;

        svg {
            fill: #0045FF !important;
        }
    }
    .md-field.md-theme-default > .md-icon:after {
        display: none;
    }

    .md-field .md-input-action {
        top: 8px!important;
    }

    .md-field.md-theme-default.md-has-textarea:not(.md-autogrow):before {
        border-color: transparent !important;
    }

    .md-field.md-theme-default.md-has-textarea:not(.md-autogrow):after {
        border-color: transparent !important;
    }

    .md-file {
        .md-file-icon {
            width: 80px;
            height: 48px;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            margin: 0;
            background-color: #0045FF;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                height: 22px;
                fill: #FFFFFF;
                transform: rotate(45deg);
            }
        }

        .md-input {
            margin: 0 !important;
            height: 48px !important;
        }
    }
}
.md-tooltip {
    color: #000000!important;
    background-color: #FFFFFF!important;
    border: 1px solid #cdd0d9;
    border-radius: 6px;
    padding: 15px;
    width: 400px!important;
    height: auto!important;
    font-size: 16px;
    line-height: 24px;
}
.vs__dropdown-toggle {
    width: 100%;
    height: 100%;
    padding-left: 20px;
}
.vs__actions {
    padding-right: 3px;
}
.vs__clear {
    display: none;
}
.vs__open-indicator {
    fill: black;
    transform: scale(0.65);
}
</style>

<style lang="scss" scoped>
.applications-form_wrap {
    .disabled {
      background-color: grey!important;
    }
    .document-status {
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: 10px;
        div {
            cursor: pointer;
        }
    }
    .applications-form_header {
        font-weight: 900;
        font-size: 36px;
        line-height: 52px;
        text-align: left;
        margin: 0 0 15px 30px;
    }

    .project-sentence {
      font-size: 20px;
      line-height: 25px;
      text-align: left;
      margin: 0 0 35px 30px;
    }

    .form-field {
        display: flex;
        align-items: center;
        margin-bottom: 25px;

        p {
            width: 190px;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            text-align: right;
        }
        .md-field {
            width: 45%;
            max-width: 410px;
            background: #FFFFFF;
            border: 1px solid #CDD0D9;
            box-sizing: border-box;
            border-radius: 4px;
            margin: 0 0 0 16px;
            padding: 0 0 0 24px;

            .md-input {
                height: 48px !important;
            }
            .md-file {
                flex-direction: row-reverse;
            }
        }
        .uploaded {
            background: #DCDCDC;
        }
        .md-field:after, .md-field:before {
            position: unset !important;
        }
        .select-field {
            width: 45%;
            max-width: 410px;
            background: #FFFFFF;
            border: 1px solid #CDD0D9;
            outline: none;
            box-sizing: border-box;
            border-radius: 4px;
            height: 48px;
            padding: 0 0 0 25px;
            margin-left: 16px;

            option {
                height: 48px;
            }
        }
    }
    .file-error-msg {
        color: red;
        margin: -16px 0 0 16px;
    }
    .form-field_textarea {
        align-items: flex-start;
        .md-field {
            padding: 0;
            .md-textarea {
                min-height: 150px;
            }
        }
    }
    .applications-error {
        margin-bottom: 0;
    }
    .cancel {
        width: 130px;
        height: 40px;
        margin-left: 25px;
        background: #FFFFFF;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            text-transform: capitalize;
            color: #000000;
        }
    }
    .next {
        width: 130px;
        height: 40px;
        margin-left: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        text-transform: capitalize;
        border-radius: 6px;
        background-color: #0045FF;
        color: #FFFFFF;

        img {
            margin-left: 13px;
        }
    }

    .next-save {
      width: 350px;
      height: 40px;
      margin-left: 16px;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      text-transform: initial;
      border-radius: 6px;
      border: 3px solid #0045FF;
    }
    .radio-wrap {
        margin-left: 16px;
    }
    .tooltip-wrap {
        margin-left: 15px;
    }
    .custom-select {
        border: none!important;
        padding: 0!important;
    }
}
.recomendationletter {
    width: 217px !important;
    margin-left: -26px;
}
</style>